import { Headers } from './models';

export function scrubHeaders(
  headers: Headers | undefined,
): Headers | undefined {
  if (!headers) {
    return headers;
  }

  return Object.fromEntries(
    Object.entries(headers).map(([key, value]) => {
      if (key.toLowerCase() === 'authorization') {
        return [key, value && `SCRUBBED (${value.length} characters)`];
      }
      return [key, value];
    }),
  );
}
