import type { User } from 'firebase/auth';
import type { FieldValue, Timestamp } from 'firebase/firestore';
import { Units, ProviderId } from '@reshima/shared';
import type {
  CustomableCategoryId,
  CustomableNamedCategory,
  CustomCategory,
} from '@reshima/category';

export enum StreamSource {
  Client = 'Client',
  Server = 'Server',
}

export type AppUser = {
  firebaseUser: User;
  isAnonymous: boolean;
  isSignedIn: boolean;
  providers: Record<ProviderId, boolean>;
  userImage: string | null;
};

export type Contact = {
  name: string;
};

export type Settings = {
  addingItem: AddingItem;
};

export type AddingItem = {
  autoComplete: AddingItemAutoComplete;
};

export type AddingItemAutoComplete = {
  parseItemCount: boolean;
  parseItemUnit: boolean;
  parseItemCategory: boolean;
};

export type FirebaseSettings = {
  addingItem: FirebaseAddingItem;
};

export type FirebaseAddingItem = {
  autoComplete: FirebaseAddingItemAutoComplete;
};

export type FirebaseAddingItemAutoComplete = Partial<AddingItemAutoComplete>;

export type UserMessageId = 'referral';

export type FirebaseUserMessage = {
  hiddenAt?: Timestamp;
  shownCount?: number;
};

export type FirebaseUserMessages = Partial<
  Record<UserMessageId, FirebaseUserMessage>
>;

export type FirebaseUserData = {
  name?: string;
  isAdmin?: boolean;
  contacts?: Record<string, Contact>;
  updatedAt?: Timestamp;
  settings?: FirebaseSettings;
  listsOrder?: string[];
  messages?: FirebaseUserMessages;
};

export type NewFirebaseUserData = Omit<FirebaseUserData, 'updatedAt'> & {
  updatedAt: FieldValue;
};

export type UserMessage = {
  hiddenAt?: Date;
  shownCount?: number;
};

export type UserMessages = Partial<Record<UserMessageId, UserMessage>>;

export type UserData = Omit<FirebaseUserData, 'updatedAt' | 'messages'> & {
  id: string;
  name: string;
  contacts: Record<string, Contact>;
  updatedAt: Date;
  settings: Settings;
  listsOrder: string[];
  messages: UserMessages;
};

export type UpdateAbleUserData = Partial<NewFirebaseUserData> & { id: string };

export enum ListSortBy {
  fixedCategories = 'fixedCategories',
  manual = 'manual',
  lastCreatedItem = 'lastCreatedItem',
  alphabetical = 'alphabetical',
}

export enum ListItemsCompletedSortBy {
  lastCheckedItem = 'lastCheckedItem',
  alphabetical = 'alphabetical',
}

export enum ListSortByDirection {
  asc = 'asc',
  desc = 'desc',
}

export type FirebaseCustomCategory = {
  name?: string;
  icon?: string;
  createdAt?: Timestamp;
};

export type FirebaseList = {
  id?: string;
  clientCreatedAt?: Timestamp;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  admins?: string[];
  sharedWith?: string[];
  itemsOrder?: string[];
  fixedCategoriesOrder?: CustomableCategoryId[];
  shareLink?: string;
  name?: string;
  sortBy?: ListSortBy;
  sortByDirection?: ListSortByDirection;
  completedItemsSortBy?: ListItemsCompletedSortBy;
  completedItemsSortByDirection?: ListSortByDirection;
  disableActivity?: boolean;
  customCategories?: Record<string, FirebaseCustomCategory>;
};

export type NewFirebaseList = {
  id: string;
  createdAt: FieldValue;
  updatedAt: FieldValue;
  shareLink: string;
  admins: string[];
};

export type List = Omit<
  FirebaseList,
  | 'clientCreatedAt'
  | 'createdAt'
  | 'updatedAt'
  | 'customCategories'
  | 'fixedCategoriesOrder'
> & {
  id: string;
  name: string;
  admins: string[];
  sharedWith: string[];
  clientCreatedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  sortBy: ListSortBy;
  sortByDirection: ListSortByDirection;
  completedItemsSortBy: ListItemsCompletedSortBy;
  completedItemsSortByDirection: ListSortByDirection;
  disableActivity: boolean;
  fixedCategoriesOrder: CustomableCategoryId[];
  customCategories: Record<string, CustomCategory>;
};

export type UpdateAbleList = Omit<
  Partial<FirebaseList>,
  'fixedCategoriesOrder'
> & {
  fixedCategoriesOrder?: FieldValue | CustomableCategoryId[];
};

export type FirebaseItem = {
  clientCreatedAt?: Timestamp;
  clientUpdatedAt?: Timestamp;
  checkedUpdatedAt?: Timestamp;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  name?: string;
  checked?: boolean;
  categoryId?: CustomableCategoryId;
  count?: number;
  unit?: Units;
  disableActivity?: boolean;
};

export type NewItem = {
  itemName: string;
  count: number;
  unit: Units;
  categoryId?: CustomableCategoryId;
};

export type Item = Omit<
  FirebaseItem,
  | 'clientCreatedAt'
  | 'clientUpdatedAt'
  | 'checkedUpdatedAt'
  | 'createdAt'
  | 'updatedAt'
> & {
  id: string;
  name: string;
  clientCreatedAt: Date;
  clientUpdatedAt: Date;
  checkedUpdatedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  categoryId: CustomableCategoryId;
  count: number;
  unit: Units;
};

export type UpdateAbleItem = Omit<
  Partial<Item>,
  | 'disableActivity'
  | 'checked'
  | 'updatedAt'
  | 'checkedUpdatedAt'
  | 'clientUpdatedAt'
> & {
  id: string;
  disableActivity?: FieldValue | true;
  checked?: FieldValue | boolean;
  checkedUpdatedAt?: FieldValue | Date;
  clientUpdatedAt?: FieldValue | Date;
  updatedAt?: FieldValue | Date;
};

export type CategoryItems = {
  category: CustomableNamedCategory;
  categoryItems: Item[];
};

export type ItemCategoryAutoCompleteCollectionItem = {
  count: number;
};

export type ItemCategoryManualUpdateCollectionItem = {
  count: number;
};

export type FirebaseParticipant = {
  name?: string;
  createdAt?: Timestamp;
};

export type Participant = Omit<FirebaseParticipant, 'createdAt'> & {
  id: string;
  name: string;
  createdAt: Date;
  index: number;
};

export type NewFirebaseParticipant = Omit<FirebaseParticipant, 'createdAt'> & {
  createdAt: FieldValue;
};

export type JoinListRequestData = {
  shareLink: string;
};

export type JoinListResponseData = {
  id: string;
};

export type LeaveListRequestData = {
  listId: string;
};

export type LeaveListResponseData = {
  success: boolean;
};

export type IsUserParticipatingInListRequestData = {
  listId: string;
};

export type IsUserParticipatingInListResponseData = {
  isParticipating: boolean;
};

export type MigrateListsRequestData = {
  toUserId: string;
};

export type MigrateListsResponseData = {
  success: boolean;
};

export type SignInTestUserRequestData = {
  email: string;
};

export type SignInTestUserResponseData = {
  token: string;
};
