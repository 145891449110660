import { AppUser } from '@reshima/firebase';
import { Headers } from '@reshima/fetch';

async function getFirebaseToken(user: AppUser): Promise<string> {
  const token = await user.firebaseUser.getIdToken();

  if (!token) {
    throw new Error('No Firebase token');
  }

  return token;
}

export async function getAuthHeaders(
  user?: AppUser,
): Promise<Headers | undefined> {
  if (!user) {
    return undefined;
  }

  const token = await getFirebaseToken(user);

  return {
    Authorization: `Bearer ${token}`,
  };
}
