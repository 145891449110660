import { getBackendHost, backendPrefix } from '@reshima/client-server';
import { BackendFetchOptions } from './models';

export function getUrl(options: BackendFetchOptions): URL {
  const { path, params } = options;

  const base = getBackendHost();

  const url = new URL(`${backendPrefix}${path}`, base);

  url.search = new URLSearchParams(params).toString();

  return url;
}
