import { reshimaFetch } from '@reshima/fetch';
import { BackendFetchOptions } from './models';
import { getUrl } from './url';
import { getAuthHeaders } from './auth';

export async function backendFetch<T>(
  options: BackendFetchOptions,
): Promise<T> {
  const url = getUrl(options);

  const authHeaders = await getAuthHeaders(options.user);

  const headers = {
    ...authHeaders,
    ...options.headers,
  };

  return reshimaFetch({
    ...options,
    url,
    headers,
  });
}
