import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { FetchException, FetchResponseError } from '@reshima/shared';
import { FetchOptions } from './models';
import { getUrl } from './url';
import { getHeaders } from './headers';
import { scrubHeaders } from './scrub';

const name = 'Fetch';

export async function reshimaFetch<T>(options: FetchOptions): Promise<T> {
  const action = Action.Fetch;

  const { method, headers } = options;

  const { url, host, path, params } = getUrl(options);

  const properties = {
    method,
    headers: scrubHeaders(headers),
    host,
    path,
    params,
    url,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const headers = getHeaders(options);

    const response = await fetch(url, {
      method,
      headers,
      ...('body' in options ? { body: JSON.stringify(options.body) } : {}),
    });

    if (!response.ok) {
      throw new FetchResponseError({ method, url, response });
    }

    const result = await response.json();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });

    return result;
  } catch (e) {
    const error =
      e instanceof FetchResponseError
        ? e
        : new FetchException({
            message: (e as Error)?.message,
            method,
            url,
          });

    trackException({
      name,
      action,
      properties,
      start,
      error,
    });

    throw error;
  }
}
