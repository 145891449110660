import { backendRoutes } from '@reshima/client-server';
import { backendFetch } from '@reshima/backend-fetch';
import { AppUser } from '@reshima/firebase';
import { UpdateCategory } from './models';

const {
  itemsCategoriesUpdates: { path },
} = backendRoutes;

export async function logUpdateCategory({
  updateCategory,
  user,
}: {
  updateCategory: UpdateCategory;
  user: AppUser;
}): Promise<void> {
  return backendFetch<void>({
    method: 'PUT',
    path,
    body: updateCategory,
    user,
  });
}
