'use client';
import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import {
  AppUser,
  anonymouslySignIn,
  signIn,
  getAuthApp,
  parseUser,
  signOut,
  UserData,
  getUserDataStream,
  signInWithCustomToken,
} from '@reshima/firebase';
import {
  ActionModifier,
  flush,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import { ProviderId, ProviderIdOrTest, TestProviderId } from '@reshima/shared';
import { useManagedSyncStatus } from '@reshima/nav-bar-ui';
import { useMigrateAnonymousUserData } from './useMigrateAnonymousUserData';
import {
  deleteLastUserId,
  setLastUserId,
} from '@reshima/user-local-persistence';
import { useAndroidAuth } from './android/useAndroidAuth';
import { useIOSAuth } from './ios/useIOSAuth';
import { useTWAToAndroidAuthSync } from './android/useTWAToAndroidAuthSync';
import { useWebViewToAndroidAuthSync } from './android/useWebViewToAndroidAuthSync';

export const testUserEmailKey = 'testUserEmail';

export type ClientAuthContext = {
  userLoading: boolean;
  user?: AppUser;
  userData?: UserData;
  testUserEmail: string;
  userDataLoading: boolean;
  isMigratingUserData: boolean;
  isSigningIn: boolean;
  signingInProviderId?: ProviderIdOrTest;
  isSigningInError: boolean;
  anonymouslySignIn: typeof anonymouslySignIn;
  signIn: typeof signIn;
  signOut: typeof signOut;
  signInWithCustomToken: typeof signInWithCustomToken;
};

export const clientAuthContext = createContext<ClientAuthContext>({
  userLoading: true,
  userDataLoading: true,
  isMigratingUserData: false,
  isSigningIn: false,
  isSigningInError: false,
  testUserEmail: '',
  signIn: () => Promise.resolve(),
  anonymouslySignIn: () => Promise.resolve(null as unknown as AppUser),
  signOut: () => Promise.resolve(),
  signInWithCustomToken: () => Promise.resolve(),
});

export function useClientAuth(): ClientAuthContext {
  return useContext(clientAuthContext);
}

export function ClientAuthProvider({
  children,
}: {
  children: React.ReactNode;
}): ReactElement {
  const name = 'ClientAuthProvider';
  const [userLoading, setUserLoading] = useState(true);
  const [isMigratingUserData, setIsMigratingUserData] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [signingInProviderId, setSigningInProviderId] =
    useState<ProviderIdOrTest>();
  const [isSigningInError, setIsSigningInError] = useState(false);
  const [testUserEmail, setTestUserEmail] = useState<string>('');
  const [user, setUser] = useState<AppUser>();
  const [userData, setUserData] = useState<UserData>();
  const [userDataLoading, setUserDataLoading] = useState(true);

  const {
    isAndroidSigningIn,
    isAndroidSignInError,
    isAndroidSignInAvailable,
    androidSigningProviderId,
    androidSignIn,
  } = useAndroidAuth();
  const {
    isIOSSigningIn,
    isIOSSignInError,
    iOSSigningProviderId,
    isIOSSignInAvailable,
    iOSSignIn,
  } = useIOSAuth();
  useTWAToAndroidAuthSync({ user, userLoading });
  useWebViewToAndroidAuthSync({ user, userLoading });

  const { setSyncGuest, setSyncByStreamSource } = useManagedSyncStatus();

  if (isMigratingUserData !== useMigrateAnonymousUserData({ fromUser: user })) {
    setIsMigratingUserData(!isMigratingUserData);
  }

  const listenToOnAuthStateChanged = useCallback(() => {
    const action = 'OnAuthStateChanged';

    const start = trackEvent({
      name,
      action,
      actionModifier: ActionModifier.Start,
    });

    return onAuthStateChanged(
      getAuthApp(),
      async (user) => {
        try {
          setTimeout(flush);

          setUserLoading(false);

          if (!user) {
            setUser(undefined);

            deleteLastUserId();

            trackEvent({
              name,
              action,
              actionModifier: ActionModifier.End,
              start,
              properties: {
                user: !!user,
              },
            });

            return;
          }

          setLastUserId({ userId: user.uid });

          const parsedUser = parseUser(user);

          setUser(parsedUser);

          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            start,
            properties: {
              user: !!user,
              providers: parsedUser.providers,
            },
          });
        } catch (error) {
          trackException({
            name,
            action,
            error,
            start,
          });
        }
      },
      (error) => {
        trackException({
          name,
          action,
          error,
        });
      },
    );
  }, []);

  const listenToUserDataStream = useCallback(
    (user?: AppUser) => {
      const action = 'GetUserDataStream';

      const start = trackEvent({
        name,
        action,
        actionModifier: ActionModifier.Start,
      });

      if (!user) {
        setUserData(undefined);
        setUserDataLoading(false);
        setSyncGuest();
        trackEvent({
          name,
          action,
          actionModifier: ActionModifier.Skip,
          start,
        });
        return;
      }

      return getUserDataStream({
        userId: user.firebaseUser.uid,
        onUpdate: ({ user, source }) => {
          setUserData(user);
          setSyncByStreamSource(source);
          setUserDataLoading(false);

          trackEvent({
            name,
            action,
            actionModifier: ActionModifier.End,
            start,
          });
        },
        onError: (error) => {
          setUserDataLoading(false);
          trackException({
            name,
            action,
            start,
            error,
          });
        },
      });
    },
    [setSyncByStreamSource, setSyncGuest],
  );

  const _signIn = useCallback(
    async (params: Parameters<typeof signIn>[0]) => {
      try {
        setIsSigningIn(true);
        setIsSigningInError(false);
        setSigningInProviderId(params.providerId);

        if (
          isAndroidSignInAvailable &&
          params.providerId === ProviderId.google
        ) {
          androidSignIn(params);
          return;
        }

        if (isIOSSignInAvailable && params.providerId !== TestProviderId.test) {
          iOSSignIn(params);
          return;
        }

        await signIn(params);
      } catch {
        setIsSigningInError(true);
      } finally {
        setSigningInProviderId(undefined);
        setIsSigningIn(false);
      }
    },
    [isAndroidSignInAvailable, isIOSSignInAvailable, androidSignIn, iOSSignIn],
  );

  useEffect(() => listenToOnAuthStateChanged(), [listenToOnAuthStateChanged]);
  useEffect(() => {
    if (userLoading) {
      return;
    }

    return listenToUserDataStream(user);
  }, [userLoading, user, listenToUserDataStream]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const _testUserEmail = searchParams.get(testUserEmailKey);
    if (_testUserEmail && _testUserEmail !== testUserEmail) {
      setTestUserEmail(_testUserEmail);
    }
  }, [testUserEmail]);

  return (
    <clientAuthContext.Provider
      value={{
        user,
        userLoading,
        userData,
        userDataLoading,
        testUserEmail,
        isMigratingUserData,
        isSigningIn: isSigningIn || isAndroidSigningIn || isIOSSigningIn,
        isSigningInError:
          isSigningInError || isAndroidSignInError || isIOSSignInError,
        signingInProviderId:
          signingInProviderId ||
          androidSigningProviderId ||
          iOSSigningProviderId,
        anonymouslySignIn,
        signIn: _signIn,
        signOut,
        signInWithCustomToken,
      }}
    >
      {children}
    </clientAuthContext.Provider>
  );
}
