import { FetchOptions } from './models';

function getURLObject(options: FetchOptions): URL {
  if ('url' in options) {
    return options.url;
  }

  const { host, path, params } = options;

  const url = new URL(path, host);

  url.search = new URLSearchParams(params).toString();

  return url;
}

export function getUrl(options: FetchOptions): {
  url: string;
  host: string;
  path: string;
  params: string;
} {
  const url = getURLObject(options);

  return {
    url: url.toString(),
    host: url.host,
    path: url.pathname,
    params: url.search,
  };
}
