import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { fetchCategory } from '@reshima/category-search';
import { logUpdateCategory } from '@reshima/items-categories-updates-shared';
import {
  CategoryId,
  CustomableCategoryId,
  isCategoryId,
} from '@reshima/category';
import { AppUser } from '@reshima/firebase';
import { Item, List } from '../models';
import { updateItemCategory } from './items';

const name = 'FirebaseItemsCategory';

export async function categoryItem({
  list,
  itemId,
  itemName,
  user,
}: {
  list: List;
  itemId: string;
  itemName: string;
  user: AppUser;
}): Promise<void> {
  const action = 'CategoryItem';

  const properties = {
    listId: list.id,
    itemId,
    name,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const categoryId = await fetchCategory({
      search: itemName,
      user,
    });

    await updateItemCategory({
      list,
      itemId,
      categoryId,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

async function logItemCategoryUpdate({
  item,
  list,
  categoryId,
  user,
}: {
  item: Item;
  list: List;
  categoryId: CategoryId;
  user: AppUser;
}): Promise<void> {
  const action = 'LogItemCategoryUpdate';

  const properties = {
    listId: list.id,
    itemId: item.id,
    categoryId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await logUpdateCategory({
      updateCategory: {
        itemName: item.name,
        categoryId,
      },
      user,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
  }
}

export async function manualUpdateCategorizedItem({
  list,
  item,
  categoryId,
  user,
}: {
  list: List;
  item: Item;
  categoryId: CustomableCategoryId;
  user?: AppUser; // TODO: rethinking optional
}): Promise<void> {
  if (isCategoryId(categoryId) && user) {
    logItemCategoryUpdate({ item, list, categoryId, user });
  }

  await updateItemCategory({
    list,
    itemId: item.id,
    categoryId,
  });
}
