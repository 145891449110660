import { FirebaseApp } from 'firebase/app';
import {
  Firestore,
  initializeFirestore,
  persistentLocalCache,
} from 'firebase/firestore';

let fireStoreApp: Firestore;

export function initFirestore(app: FirebaseApp): void {
  fireStoreApp = initializeFirestore(app, {
    localCache: persistentLocalCache(),
  });
}

export function getFirestoreApp() {
  return fireStoreApp;
}
