import { backendRoutes } from '@reshima/client-server';
import { backendFetch } from '@reshima/backend-fetch';
import { categoriesMap, CategoryId } from '@reshima/category';
import { AppUser } from '@reshima/firebase';
import { CategoriesBatchPayload, SearchResults } from './models';

const { category, categoryAutoComplete } = backendRoutes;

export async function fetchCategory({
  search,
  user,
}: {
  search: string;
  user: AppUser;
}): Promise<CategoryId> {
  const { path, query } = category;

  const params = {
    [query.search]: search,
  };

  if (!search) {
    return categoriesMap.Other.id;
  }

  const results = await backendFetch<SearchResults>({
    method: 'GET',
    path,
    params,
    user,
  });

  return results.bestMatch.id;
}

export async function fetchSuggestCategory({
  search,
  user,
}: {
  search: string;
  user: AppUser;
}): Promise<CategoryId> {
  const { path, query } = categoryAutoComplete;

  const params = {
    [query.search]: search,
  };

  if (!search) {
    return categoriesMap.Other.id;
  }

  const results = await backendFetch<SearchResults>({
    method: 'GET',
    path,
    params,
    user,
  });

  return results.bestMatch.id;
}

export async function batchGetCategories({
  searches,
  user,
}: {
  searches: string[];
  user: AppUser;
}): Promise<Record<string, SearchResults>> {
  const { path } = backendRoutes.categoriesBatch;

  const body: CategoriesBatchPayload = { searches };

  return backendFetch<Record<string, SearchResults>>({
    method: 'POST',
    path,
    body,
    user,
  });
}
