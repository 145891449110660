import { FirebaseOptions, initializeApp } from 'firebase/app';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { initAuth } from './firebase-auth';
import { initFirestore } from './firebase-firestore';
import { initCallable } from './firebase-callable';

const name = 'Firebase';

export function initialize({ config }: { config: FirebaseOptions }) {
  const action = 'Initialize';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    const app = initializeApp(config);

    initFirestore(app);

    initAuth({ app });

    initCallable(app);

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
    throw error;
  }
}
