import { FetchOptions, Method, Headers } from './models';

const contentTypeJSON = {
  'Content-Type': 'application/json',
};

const headersByMethod: Record<Method, Headers | undefined> = {
  GET: undefined,
  POST: contentTypeJSON,
  PUT: contentTypeJSON,
  DELETE: undefined,
  PATCH: contentTypeJSON,
};

export function getHeaders({ headers, method }: FetchOptions): Headers {
  return {
    ...headers,
    ...headersByMethod[method],
  };
}
