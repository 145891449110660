import {
  User,
  GoogleAuthProvider,
  signInAnonymously,
  signInWithCredential as _signInWithCredential,
  OAuthCredential,
  linkWithCredential,
  signInWithPopup,
  UserCredential,
  OAuthProvider,
  AuthProvider,
  signInWithCustomToken as _signInWithCustomToken,
  browserPopupRedirectResolver,
} from 'firebase/auth';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { isChromeExtension, ProviderId, TestProviderId } from '@reshima/shared';
import {
  createBackgroundUserCredentialRequestMessage,
  isErrorMessage,
  isPopupUserCredentialResponseMessage,
} from '@reshima/messages';
import { AppUser } from './models';
import { getAuthApp } from './firebase-auth';
import { postSignInTestUser } from './http-functions';

const name = 'Auth';

const appleProviderId = 'apple.com';

function isSignedInByGoogle(user: User): boolean {
  return (
    user?.providerData.some(
      ({ providerId }) => providerId === GoogleAuthProvider.PROVIDER_ID,
    ) || false
  );
}

function isSignedInByApple(user: User): boolean {
  return (
    user?.providerData.some(
      ({ providerId }) => providerId === appleProviderId,
    ) || false
  );
}

function getUserImage(user: User): string | null {
  return user?.providerData.find(({ photoURL }) => photoURL)?.photoURL || null;
}

function getProviders(user: User): Record<ProviderId, boolean> {
  return {
    [ProviderId.google]: isSignedInByGoogle(user),
    [ProviderId.apple]: isSignedInByApple(user),
  };
}

export function parseUser(firebaseUser: User): AppUser {
  return {
    firebaseUser: firebaseUser,
    isAnonymous: firebaseUser.isAnonymous,
    isSignedIn: !firebaseUser.isAnonymous,
    providers: getProviders(firebaseUser),
    userImage: getUserImage(firebaseUser),
  };
}

export async function anonymouslySignIn(): Promise<AppUser> {
  const action = 'AnonymouslySignIn';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    const credentials = await signInAnonymously(getAuthApp());

    const parsedUser = parseUser(credentials.user);

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
    });

    return parsedUser;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
    throw error;
  }
}

async function extensionSignInProvider({
  providerId,
  existingUser,
}: {
  providerId: ProviderId;
  existingUser: AppUser | null;
}): Promise<void> {
  const action = 'ExtensionSignInProvider';

  const properties = { providerId, existingUser: !!existingUser };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const userCredential = await getOffscreenCredential({ providerId });

    const oAuthCredential =
      GoogleAuthProvider.credentialFromResult(userCredential);

    if (!oAuthCredential) {
      throw new Error('No OAuth credential');
    }

    await (existingUser
      ? linkWithCredential(existingUser.firebaseUser, oAuthCredential)
      : _signInWithCredential(getAuthApp(), oAuthCredential));

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

async function getOffscreenCredential({
  providerId,
}: {
  providerId: ProviderId;
}): Promise<UserCredential> {
  const action = 'GetOffscreenCredential';

  const properties = { providerId };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  const message = createBackgroundUserCredentialRequestMessage({ providerId });

  return new Promise<UserCredential>((resolve, reject) => {
    chrome.runtime.sendMessage(message, (response: unknown) => {
      if (!response) {
        const error = chrome.runtime.lastError || new Error('No response');

        trackException({
          name,
          action,
          error,
          start,
          properties,
        });
        return reject(error);
      }

      if (isErrorMessage(response)) {
        trackException({
          name,
          action,
          error: response.error,
          start,
          properties,
        });
        return reject(response.error);
      }

      if (!isPopupUserCredentialResponseMessage(response)) {
        const error = new Error('Unexpected response');

        trackException({
          name,
          action,
          error,
          start,
          properties,
        });
        return reject(error);
      }

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        start,
        properties,
      });

      resolve(response.userCredential);
    });
  });
}

export type SignInCredentials = {
  providerId: ProviderId;
  idToken: string;
  accessToken?: string;
  email?: string;
};

export async function signInWithCredential({
  providerId,
  idToken,
  accessToken,
  email,
  rawNonce,
}: {
  providerId: ProviderId;
  idToken: string;
  accessToken?: string;
  email?: string;
  rawNonce?: string;
}): Promise<void> {
  const action = 'SignInWithCredential';

  const properties = {
    providerId,
    idToken: !!idToken,
    accessToken: !!accessToken,
    email: !!email,
    rawNonce: !!rawNonce,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const oAuthCredential = getOAuthCredential({
      providerId,
      idToken,
      accessToken,
      rawNonce,
    });

    await _signInWithCredential(getAuthApp(), oAuthCredential);

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

function getCurrentUser(): AppUser | null {
  const firebaseUser = getAuthApp().currentUser;

  return firebaseUser && parseUser(firebaseUser);
}

export async function signInWithPopupProvider({
  providerId,
}: {
  providerId: ProviderId;
}): Promise<UserCredential> {
  const action = 'SignInWithPopupProvider';

  const properties = { providerId };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const authProvider = authProviders[providerId]();

    const userCredential = await signInWithPopup(
      getAuthApp(),
      authProvider,
      browserPopupRedirectResolver,
    );

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });

    return userCredential;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

function getAppleProvider(): OAuthProvider {
  const provider = new OAuthProvider(appleProviderId);

  provider.setCustomParameters({
    locale: 'he',
  });

  return provider;
}

const authProviders: Record<ProviderId, () => AuthProvider> = {
  [ProviderId.google]: () => new GoogleAuthProvider(),
  [ProviderId.apple]: getAppleProvider,
};

function getOAuthCredential({
  providerId,
  idToken,
  accessToken,
  rawNonce,
}: {
  providerId: ProviderId;
  idToken: string;
  accessToken?: string;
  rawNonce?: string;
}): OAuthCredential {
  if (providerId === ProviderId.google) {
    return GoogleAuthProvider.credential(idToken, accessToken);
  }

  if (providerId === ProviderId.apple) {
    return getAppleProvider().credential({
      idToken,
      rawNonce,
    });
  }

  throw new Error('Unknown provider');
}

async function signInTestUser({ email }: { email: string }): Promise<void> {
  const action = 'SignInTestUser';

  const properties = { email };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const { token: customToken } = await postSignInTestUser({ email });

    await signInWithCustomToken({ customToken });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

async function executeSignIn({
  providerId,
  existingUser,
}: {
  providerId: ProviderId;
  existingUser: AppUser | null;
}): Promise<void> {
  if (isChromeExtension()) {
    return extensionSignInProvider({ providerId, existingUser });
  }

  await signInWithPopupProvider({ providerId });
}

export async function signInWithCustomToken({
  customToken,
}: {
  customToken: string;
}): Promise<void> {
  const action = 'SignInWithCustomToken';

  const properties = { customTokenLength: customToken?.length };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await _signInWithCustomToken(getAuthApp(), customToken);

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function signIn(
  params:
    | {
        providerId: ProviderId;
      }
    | {
        providerId: TestProviderId;
        email: string;
      },
): Promise<void> {
  const action = 'SignIn';

  const { providerId } = params;

  const isTest = providerId === TestProviderId.test;

  const properties = {
    ...params,
    isTest,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    if (isTest) {
      await signInTestUser({ email: params.email });

      trackEvent({
        name,
        action,
        actionModifier: ActionModifier.End,
        properties,
        start,
      });
      return;
    }

    const existingUser = getCurrentUser();

    await executeSignIn({ providerId, existingUser });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function signOut(): Promise<void> {
  const action = 'SignOut';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    await getAuthApp().signOut();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
    throw error;
  }
}
