import { groupArrayBy } from '@reshima/shared';
import {
  CategoryId,
  CustomCategory,
  CustomableCategoryId,
  CustomableNamedCategory,
  categoriesMap,
  isCategoryId,
  itemCategories,
  sortCategoriesByOrder,
} from '@reshima/category';
import {
  AppUser,
  CategoryItems,
  Item,
  List,
  ListItemsCompletedSortBy,
  ListSortBy,
  ListSortByDirection,
} from './models';

const { Other } = categoriesMap;

export function isItemChecked({ checked }: Pick<Item, 'checked'>): boolean {
  return !!checked;
}

export function isItemUnchecked({ checked }: Pick<Item, 'checked'>): boolean {
  return !checked;
}

function getCategory({
  categoryId,
  categoriesDictionary,
  customCategories,
}: {
  categoryId: CustomableCategoryId;
  categoriesDictionary: Record<CategoryId, string>;
  customCategories: Record<string, CustomCategory>;
}): CustomableNamedCategory {
  if (isCategoryId(categoryId)) {
    return {
      id: categoryId,
      name: categoriesDictionary[categoryId],
      icon: categoriesMap[categoryId].icon,
    };
  }

  if (categoryId in customCategories) {
    return customCategories[categoryId];
  }

  return Other;
}

export function sortItemsByNameAsc(a: Item, b: Item): number {
  return a.name.localeCompare(b.name);
}

export function sortItemsByNameDesc(a: Item, b: Item): number {
  return b.name.localeCompare(a.name);
}

export function sortItemsByCheckedUpdatedAtDesc(a: Item, b: Item): number {
  return b.checkedUpdatedAt.getTime() - a.checkedUpdatedAt.getTime();
}

export function sortItemsByCheckedUpdatedAtAsc(a: Item, b: Item): number {
  return a.checkedUpdatedAt.getTime() - b.checkedUpdatedAt.getTime();
}

export function sortListsByCreatedAtAsc(a: List, b: List): number {
  return a.createdAt.getTime() - b.createdAt.getTime();
}

function reduceItemsByCategory({
  items,
  categoriesDictionary,
  customCategories,
}: {
  items: Item[];
  categoriesDictionary: Record<CategoryId, string>;
  customCategories: Record<string, CustomCategory>;
}): Record<CustomableCategoryId, CategoryItems> {
  return Object.fromEntries(
    Object.entries(
      groupArrayBy({
        array: items,
        key: 'categoryId',
      }),
    ).map(([categoryId, categoryItems]) => {
      const category = getCategory({
        categoryId,
        customCategories,
        categoriesDictionary,
      });

      return [categoryId, { category, categoryItems }];
    }),
  );
}

export function getListManualSortedItems({
  list,
  items,
}: {
  list: List;
  items: Item[];
}): Item[] {
  const itemsOrderMap: Record<string, number> = Object.fromEntries(
    (list.itemsOrder || []).map((itemId, index) => [itemId, index]),
  );

  return items.sort((a, b) => {
    if (
      Number.isNaN(Number(itemsOrderMap[a.id])) ||
      Number.isNaN(Number(itemsOrderMap[b.id]))
    ) {
      return sortItemsByCheckedUpdatedAtDesc(a, b);
    }

    return itemsOrderMap[a.id] - itemsOrderMap[b.id];
  });
}

export function getManualSortedLists({
  listsOrder,
  lists,
}: {
  listsOrder: string[];
  lists: List[];
}): List[] {
  const listsOrderMap: Record<string, number> = Object.fromEntries(
    (listsOrder || []).map((listId, index) => [listId, index]),
  );

  return lists.sort((a, b) => {
    if (
      Number.isNaN(Number(listsOrderMap[a.id])) ||
      Number.isNaN(Number(listsOrderMap[b.id]))
    ) {
      return sortListsByCreatedAtAsc(a, b);
    }

    return listsOrderMap[a.id] - listsOrderMap[b.id];
  });
}

export function getSortedItemsByCategory({
  items,
  customCategories,
  categoriesDictionary,
  categoriesOrder,
}: {
  items: Item[];
  customCategories: Record<string, CustomCategory>;
  categoriesDictionary: Record<CategoryId, string>;
  categoriesOrder?: CustomableCategoryId[];
}): CategoryItems[] {
  return sortCategoriesItemsByOrder({
    categoriesOrder,
    categoriesItems: Object.values(
      reduceItemsByCategory({ items, categoriesDictionary, customCategories }),
    ),
  });
}

export function getAllCategoriesItems({
  items,
  customCategories,
  categoriesDictionary,
  categoriesOrder,
}: {
  items: Item[];
  customCategories: Record<string, CustomCategory>;
  categoriesDictionary: Record<CategoryId, string>;
  categoriesOrder?: CustomableCategoryId[];
}): CategoryItems[] {
  const categoriesItems = reduceItemsByCategory({
    items,
    customCategories,
    categoriesDictionary,
  });

  const allCategoriesItems = Object.fromEntries(
    itemCategories.map(({ id, icon }) => {
      const category: CustomableNamedCategory = {
        id,
        name: categoriesDictionary[id],
        icon,
      };

      return [category.id, { category, categoryItems: [] }];
    }),
  );

  const mergedCategoriesItems = {
    ...allCategoriesItems,
    ...categoriesItems,
  };

  return sortCategoriesItemsByOrder({
    categoriesOrder,
    categoriesItems: Object.values(mergedCategoriesItems),
  });
}

function sortCategoriesItemsByOrder({
  categoriesOrder = [],
  categoriesItems,
}: {
  categoriesOrder?: CustomableCategoryId[];
  categoriesItems: CategoryItems[];
}): CategoryItems[] {
  const categoriesOrderMap = Object.fromEntries(
    categoriesOrder.map((category, index) => [category, index]),
  ) as Record<CategoryId, number>;

  return categoriesItems.sort((a, b) =>
    sortCategoriesByOrder({
      a: a.category.id,
      b: b.category.id,
      categoriesOrderMap,
    }),
  );
}

export function getSortedItems({
  items,
  customCategories,
  categoriesOrder,
  categoriesDictionary,
}: {
  items: Item[];
  customCategories: Record<string, CustomCategory>;
  categoriesOrder: CustomableCategoryId[];
  categoriesDictionary: Record<CategoryId, string>;
}): Item[] {
  const categoriesItems = getSortedItemsByCategory({
    items,
    customCategories,
    categoriesDictionary,
    categoriesOrder,
  });

  return categoriesItems
    .map(({ categoryItems }) =>
      categoryItems.sort(sortItemsByCheckedUpdatedAtDesc),
    )
    .flat();
}

export function isListAdmin({
  list: { admins },
  user: {
    firebaseUser: { uid },
  },
}: {
  list: List;
  user: AppUser;
}): boolean {
  return admins.includes(uid);
}

export function isListSortBy(sortBy?: unknown): sortBy is ListSortBy {
  return Object.values(ListSortBy).includes(sortBy as ListSortBy);
}

export function isListItemsCompletedSortBy(
  listItemsCompletedSortBy?: unknown,
): listItemsCompletedSortBy is ListItemsCompletedSortBy {
  return Object.values(ListItemsCompletedSortBy).includes(
    listItemsCompletedSortBy as ListItemsCompletedSortBy,
  );
}

export function isListSortByDirection(
  sortByDirection?: unknown,
): sortByDirection is ListSortByDirection {
  return Object.values(ListSortByDirection).includes(
    sortByDirection as ListSortByDirection,
  );
}
