import { FirebaseApp } from 'firebase/app';
import {
  Auth,
  browserLocalPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth';

let authApp: Auth;

export function initAuth({ app }: { app: FirebaseApp }) {
  authApp = initializeAuth(app, {
    persistence: [indexedDBLocalPersistence, browserLocalPersistence],
  });
}

export function getAuthApp() {
  return authApp;
}
