import { categoriesMap } from './categories';
import { CustomableCategoryId, CategoryId } from './models';

const { Other, Loading } = categoriesMap;

export function sortCategoriesByOrder({
  a,
  b,
  categoriesOrderMap,
}: {
  a: CustomableCategoryId;
  b: CustomableCategoryId;
  categoriesOrderMap: Record<CategoryId, number>;
}): number {
  if (a === Loading.id) {
    return -1;
  }

  if (b === Loading.id) {
    return 1;
  }

  if (a === Other.id) {
    return 1;
  }

  if (b === Other.id) {
    return -1;
  }

  if (a in categoriesOrderMap && b in categoriesOrderMap) {
    return (
      categoriesOrderMap[a as CategoryId] - categoriesOrderMap[b as CategoryId]
    );
  }

  if (a in categoriesOrderMap) {
    return 1;
  }

  if (b in categoriesOrderMap) {
    return -1;
  }

  return a.localeCompare(b);
}
