import { reshimaFetch } from '@reshima/fetch';
import { firebaseRegion } from './constants';
import {
  SignInTestUserRequestData,
  SignInTestUserResponseData,
} from './models';

type FunctionName = 'signInTestUser';

function getHttpFunctionUrl(functionName: FunctionName): URL {
  return new URL(
    `https://${firebaseRegion}-${process.env['NEXT_PUBLIC_FIREBASE_PROJECT_ID']}.cloudfunctions.net/${functionName}`,
  );
}

export async function postSignInTestUser(
  body: SignInTestUserRequestData,
): Promise<SignInTestUserResponseData> {
  const url = getHttpFunctionUrl('signInTestUser');

  return reshimaFetch<SignInTestUserResponseData>({
    method: 'POST',
    url,
    body,
  });
}
