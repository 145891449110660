import { FirebaseApp } from 'firebase/app';
import { Functions, getFunctions } from 'firebase/functions';
import { firebaseRegion } from './constants';

let callable: Functions;

export function initCallable(app: FirebaseApp): void {
  callable = getFunctions(app, firebaseRegion);
}

export function getCallable() {
  return callable;
}
