import {
  Category,
  CategoryId,
  CustomableCategoryId,
  NamedCategory,
} from './models';
import { allCategoriesData, statedCategoriesData } from './categories-data';

export const categoriesMap = Object.fromEntries(
  Object.entries(allCategoriesData).map(([id, category]) => [
    id,
    { ...category, id },
  ]),
) as Record<CategoryId, NamedCategory>;

export const defaultFixedCategoriesOrder: CategoryId[] = [
  categoriesMap.Batteries.id,
  categoriesMap.Appliances.id,
  categoriesMap.ElectronicsAndPhones.id,
  categoriesMap.WritingSupplies.id,
  categoriesMap.ToysAndGames.id,
  categoriesMap.Sport.id,
  categoriesMap.Vehicle.id,
  categoriesMap.WorkTools.id,
  categoriesMap.Garden.id,
  categoriesMap.CampingAndTraveling.id,
  categoriesMap.GrillAndBarbecue.id,
  categoriesMap.BedsAndMattresses.id,
  categoriesMap.BagsAndLuggages.id,
  categoriesMap.HeadCoverings.id,
  categoriesMap.Footwear.id,
  categoriesMap.Garments.id,
  categoriesMap.Textile.id,
  categoriesMap.Laundry.id,
  categoriesMap.Bathroom.id,
  categoriesMap.Toilet.id,
  categoriesMap.Furniture.id,
  categoriesMap.OrganicAndHealthy.id,
  categoriesMap.VitaminsAndSupplements.id,
  categoriesMap.NutsAndDriedFruits.id,
  categoriesMap.CerealsAndGrainSnacks.id,
  categoriesMap.BabyFood.id,
  categoriesMap.SnacksAndSweets.id,
  categoriesMap.BreadsAndBakery.id,
  categoriesMap.Spices.id,
  categoriesMap.CookingAndBaking.id,
  categoriesMap.SaucesAndSpreads.id,
  categoriesMap.Beverages.id,
  categoriesMap.TeaAndCoffee.id,
  categoriesMap.Canning.id,
  categoriesMap.Nails.id,
  categoriesMap.Makeup.id,
  categoriesMap.Hair.id,
  categoriesMap.Shaving.id,
  categoriesMap.Pharmacy.id,
  categoriesMap.PharmacyAndBaby.id,
  categoriesMap.Babies.id,
  categoriesMap.Optics.id,
  categoriesMap.OralHygiene.id,
  categoriesMap.PapersAndWipes.id,
  categoriesMap.Insecticides.id,
  categoriesMap.Cleaning.id,
  categoriesMap.Disposable.id,
  categoriesMap.KitchenAndHosting.id,
  categoriesMap.Animals.id,
  categoriesMap.CigarettesAndSmoking.id,
  categoriesMap.Fruits.id,
  categoriesMap.Vegetables.id,
  categoriesMap.DairyAndEggs.id,
  categoriesMap.Fish.id,
  categoriesMap.MeatPoultry.id,
  categoriesMap.ChilledAndFrozenFood.id,
  categoriesMap.Other.id,
];

export const defaultFixedCategoriesOrderMap = Object.fromEntries(
  defaultFixedCategoriesOrder.map((category, index) => [category, index]),
);

export const categories: Category[] = Object.entries(categoriesMap)
  .sort(
    ([, a], [, b]) =>
      defaultFixedCategoriesOrderMap[a.id] -
      defaultFixedCategoriesOrderMap[b.id],
  )
  .map<Category>(([id, { icon }]) => ({ id, icon }) as Category);

export const itemCategories: Category[] = categories.filter(
  ({ id }) => !(id in statedCategoriesData),
);

export const itemCategoriesIds = itemCategories.map(({ id }) => id);

export function isCategoryId(categoryId?: string): categoryId is CategoryId {
  return !!(categoryId && categoryId in categoriesMap);
}

export function getCategoryIcon(categoryId?: CustomableCategoryId): string {
  return isCategoryId(categoryId)
    ? categoriesMap[categoryId].icon
    : categoriesMap.Other.icon;
}

export function parseCategoryId({
  department,
}: {
  department: string;
}): CategoryId {
  const categoryId = Object.values(categoriesMap).find(
    (category) => category.name === department,
  )?.id;

  if (!categoryId) {
    throw new Error(`Category not found: ${department}`);
  }

  return categoryId;
}
